import {
  FetchMaterialsSetMaterialClientId,
  FetchMaterialsSetByUserIdScopeType
} from './materialsSetsTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class MaterialsSetScope {
  static userLibraryScope(
    materialClientId: FetchMaterialsSetMaterialClientId
  ): FetchMaterialsSetByUserIdScopeType {
    return `user-${materialClientId}-library`;
  }

  static companyLibraryScope(
    teamNanoId: TeamNanoID
  ): FetchMaterialsSetByUserIdScopeType {
    return `company-${teamNanoId}-library`;
  }

  static submitToTaskScope(
    materialClientId: FetchMaterialsSetMaterialClientId
  ): FetchMaterialsSetByUserIdScopeType {
    return `${materialClientId}-submit-to-task`;
  }

  static submitToProjectScope(
    materialClientId: FetchMaterialsSetMaterialClientId
  ): FetchMaterialsSetByUserIdScopeType {
    return `${materialClientId}-submit-to-project`;
  }

  static createProjectTasksScope(
    fieldId: string
  ): FetchMaterialsSetByUserIdScopeType {
    return `${fieldId}-create-project-tasks`;
  }
}
